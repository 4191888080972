import { useEffect, useState } from "react";
import { useRouter } from "next/router";

export const HeaderImageBanner = ({ imageUrl, altText }) => {
  const router = useRouter();
  const [imgLoaded, setImgLoaded] = useState(false);

  // 이미지 로드 이벤트 핸들러
  const handleLoad = () => {
    setImgLoaded(true);
  };

  const handleError = () => {
    // console.error("이미지 로드 실패:");
  };

  return (
    <img
      src={imageUrl}
      alt={altText || "배너 이미지"}
      style={{
        width: "100%",
        height: "100%",
        objectFit: "cover",
      }}
      onLoad={handleLoad}
      onError={handleError}
    />
  );
};
