import { create } from "zustand";

const useBannerStore = create((set) => ({
  // 초기 상태
  headerMenu: [],
  mainAward: [],
  mainStripe: [],
  mainImageStripe: [],
  customSm: [],
  customLg: [],
  interview: [],
  mainBtmM: [],
  mainBtmS: [],
  mainBtmThirdCenter: [],
  mainBtmThirdLeft: [],
  mainBtmThirdRight: [],
  mainEventStripe: [],
  mainSide: [],
  mainTop: [],
  mainTopCenterEvent: [],
  mainTopEvent: [],
  mobileBtm: [],
  prodLeftBtm: [],

  // 액션
  setBannerData: (bannerData) =>
    set((state) => ({
      headerMenu: bannerData.headerMenu || state.headerMenu,
      mainAward: bannerData.mainAward || state.mainAward,
      mainStripe: bannerData.mainStripe || state.mainStripe,
      mainImageStripe: bannerData.mainImageStripe || state.mainImageStripe,
      customSm: bannerData.customSm || state.customSm,
      customLg: bannerData.customLg || state.customLg,
      interview: bannerData.interview || state.interview,
      mainBtmM: bannerData.mainBtmM || state.mainBtmM,
      mainBtmS: bannerData.mainBtmS || state.mainBtmS,
      mainBtmThirdCenter:
        bannerData.mainBtmThirdCenter || state.mainBtmThirdCenter,
      mainBtmThirdLeft: bannerData.mainBtmThirdLeft || state.mainBtmThirdLeft,
      mainBtmThirdRight:
        bannerData.mainBtmThirdRight || state.mainBtmThirdRight,
      mainEventStripe: bannerData.mainEventStripe || state.mainEventStripe,
      mainSide: bannerData.mainSide || state.mainSide,
      mainTop: bannerData.mainTop || state.mainTop,
      mainTopCenterEvent:
        bannerData.mainTopCenterEvent || state.mainTopCenterEvent,
      mainTopEvent: bannerData.mainTopEvent || state.mainTopEvent,
      mobileBtm: bannerData.mobileBtm || state.mobileBtm,
      prodLeftBtm: bannerData.prodLeftBtm || state.prodLeftBtm,
    })),

  // 특정 배너 타입만 업데이트
  setHeaderMenu: (headerMenu) => set({ headerMenu }),
  setMainAward: (mainAward) => set({ mainAward }),
  setMainStripe: (mainStripe) => set({ mainStripe }),
  setMainImageStripe: (mainImageStripe) => set({ mainImageStripe }),
}));

export default useBannerStore;
