import Link from "next/link";
import { useRouter } from "next/router";
import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { BANNER_COOKIE_NAME, getCookie, setCookie } from "../../common/auth";
import { changeNotiConfirmOption, logoutConfirm } from "../../common/confirm";
import {
  openConfirmModal,
  modifyConfirmModal,
  closeConfirmModal,
} from "../../redux/action/confirm";
import { removeCookie } from "../../common/auth";
import {
  clearBasketsAction,
  deleteBasketsAction,
  fetchBasketsAction,
  initBaskets,
  insertBasketsAction,
} from "../../redux/action/baskets";
import { updateProductCategory } from "../../redux/action/productFiltersAction";
import { isLoaded, isLoading } from "../../redux/action/loading";
import { postMember } from "../../api/members";
import { failAlert, successAlert } from "../../common/alert";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/css/pagination";
import { fetchDummyList } from "../../redux/action/dummy";
import { PageRouter } from "../../common/pageRouter";
import UserFloating from "../floating/user";
import MobileSearch from "./mobileSearch/MobileSearch";
import {
  clearCart,
  decreaseCnt,
  deleteFromCart,
  increaseCnt,
} from "../../redux/action/cart";
import { fetchSiteInfo } from "../../redux/action/siteInfo";
import ProceedingMenuBadge from "../../common/badge/proceedingMenuBadge";
import StripeBanner from "./header/StripeBanner";
import FirstHeader from "./header/FirstHeader";
import SecondHeader from "./header/SecondHeader";
import { initUserInfo } from "../../redux/action/userInfo";
import ImageBanner from "@components/layout/header/ImageBanner";
import Image from "next/image";
import { useBannerQuery } from "@hook/useBannerQuery";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "@common/queryKeys";
import { HeaderImageBanner } from "@components/layout/header/HeaderImageBanner";

SwiperCore.use([Navigation, Pagination]);

const Header = ({
  logo,
  show,
  totalCartItems,
  toggleClick,
  openConfirmModal,
  modifyConfirmModal,
  closeConfirmModal,
  userInfo,
  deleteBasketsAction,
  insertBasketsAction,
  fetchBasketsAction,
  clearBasketsAction,
  basketItems,
  basketsCount,
  initBaskets,
  deleteFromCart,
  updateProductCategory,
  productFilters,
  loading,
  userCoupons,
  isLoaded,
  isLoading,
  fetchDummyList,
  dummyList,
  vp,
  cartItems,
  clearCart,
  decreaseCnt,
  increaseCnt,
  fetchSiteInfo,
  siteInfo,
  initUserInfo,
  // banners,
}) => {
  const router = useRouter();
  const [isToggled, setToggled] = useState(false);
  const [scroll, setScroll] = useState(0);
  const [btnText, setBtnText] = useState("");
  const [isUser, setIsUser] = useState(false);
  const [decideUrl, setDecideUrl] = useState("");
  const categoriRef = useRef(null);
  const [isToggleActive, setIsToggleActive] = useState(false);
  const [notificationPermission, setNotificationPermission] = useState("");
  const [awardIdx, setAwardIdx] = useState(0);
  const [menus, setMenus] = useState(null);
  const [searchToggled, setSearchToggled] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const scrollSearchBtnRef = useRef(null);
  const [showImageBanner, setShowImageBanner] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);

  const {
    refetch,
    isFetching,
    bannerList,
    headerMenu,
    mainAward,
    mainStripe,
    mainImageStripe,
  } = useBannerQuery();

  const queryClient = useQueryClient();

  useEffect(() => {
    // 첫 렌더링인 경우에만 쿼리 무효화 및 강제 실행
    if (isInitialRender) {
      // 배너 데이터 강제 요청
      refetch();

      // 초기 렌더링 완료 표시
      setIsInitialRender(false);
    }
  }, [queryClient, refetch, isInitialRender]);

  // 모바일 여부 확인
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  const handleClose = () => {
    // 배너 숨김 상태로 설정
    setShowImageBanner(false);

    // 1일 후 만료되는 쿠키 설정
    const expiryDate = new Date();

    // 당일 밤 11:59:59로 설정
    expiryDate.setHours(23, 59, 59, 999);

    // 현재 시간 확인
    const now = new Date();
    if (now > expiryDate) {
      // 이미 당일 밤 11:59:59를 지났다면 다음날 밤 11:59:59로 설정
      expiryDate.setDate(expiryDate.getDate() + 1);
    }

    // 쿠키를 "true"로 설정하여 배너 숨김 유지
    setCookie(BANNER_COOKIE_NAME, "true", {
      path: "/",
      expires: expiryDate,
    });
  };

  const onClickSearch = () => {
    setSearchToggled(!searchToggled);
  };

  const isTokenValid = () => {
    const token = getCookie("token");
    const loginUrl = "login";
    const mypageUrl = "mypage/order";

    if (!token) return setDecideUrl(loginUrl);
    else return setDecideUrl(mypageUrl);
  };
  // 사용자 push 알림 허용 유무 정보
  const getPushStats = () => {
    if (userInfo?.Member?.pushYn === "Y") {
      setIsToggleActive(true);
    } else setIsToggleActive(false);
  };
  // 사용자 알림 on/off 설정 action handler
  const onClickToggleBtnActionHandler = async () => {
    setIsToggleActive(!isToggleActive);

    const body = {
      modes: "PushSet",
      select: !isToggleActive === true ? "Y" : "N",
    };

    const res = await postMember(body);

    if (res.Code === "0000") {
      closeConfirmModal();
      return successAlert(res.Data.Msg);
    } else {
      closeConfirmModal();
      return failAlert(res.Data.MSg);
    }
  };
  // 사용자 알림 on/off 설정
  const onClickToggleBtn = () => {
    modifyConfirmModal({
      ...changeNotiConfirmOption,
      onAction: () => {
        onClickToggleBtnActionHandler();
      },
    });
    return openConfirmModal();
  };
  // 토큰 유무에 따른 로그인 버튼 레이아웃 분기처리
  const showBtnText = () => {
    if (!getCookie("token")) {
      setIsUser(false);
      return setBtnText("로그인");
    } else if (getCookie("token")) {
      setIsUser(true);
      return setBtnText("로그아웃");
    }
  };

  const userName = useMemo(() => {
    if (userInfo?.Member?.name) {
      return userInfo?.Member?.name + "님";
    } else {
      return null;
    }
  }, [userInfo]);

  const userCompanyName = useMemo(() => {
    if (userInfo?.Company?.Data?.code) {
      return userInfo?.Company?.Data?.name;
    } else {
      return "";
    }
  }, [userInfo]);

  // 토큰 유무에 따른 로그인 및 로그아웃 액션 처리
  const authHandler = () => {
    if (!getCookie("token")) {
      return router.push("/login");
    } else {
      modifyConfirmModal(logoutConfirm(logoutConfirmHandler));
      return openConfirmModal();
    }
  };
  // 로그아웃 핸들러
  const logoutConfirmHandler = () => {
    removeCookie("token");
    initUserInfo();
    if (getCookie("username")) removeCookie("username");

    if (router.asPath === "/") {
      initBaskets();
      closeConfirmModal();
      router.reload();
    } else {
      initBaskets();
      closeConfirmModal();
      router.push("/");
    }
  };

  const hadleClickOutSide = (e) => {
    if (categoriRef.current) {
      if (!categoriRef.current?.contains(e.target)) {
        categoriRef.current.children[1].style.visibility = "hidden";
        categoriRef.current.children[1].style.zIndex = -1;
      } else {
        categoriRef.current.children[1].style.visibility = "visible";
        categoriRef.current.children[1].style.zIndex = 99;
      }
    }
  };

  // 메뉴 라우팅
  const buildPage = (pages) => {
    if (!pages) return;
    const temp = [...pages];

    // 시제품 판촉물 필터링
    const filteredPages = temp.map((page) => {
      if (page.sub) {
        return {
          ...page,
          sub: page.sub.filter((subPage) => {
            // 시제품 제작 메뉴는 siteConfig.Prototype이 null이 아닐 때만 표시
            if (subPage.url === "/customizing/prototype") {
              return siteInfo?.Prototype !== null;
            }
            if (subPage.url === "/customizing/promotion") {
              return siteInfo?.Promotion !== null;
            }
            return true;
          }),
        };
      }
      return page;
    });

    const menus = filteredPages.map((page, pageIdx) => {
      if (!page.sub) {
        // 현재 경로에서 쿼리 제거
        const currentPath = router.asPath.split("?")[0];
        let targetPath = page.url.split("?")[0];

        // targetPath가 상대 경로라면 절대 경로로 변환
        if (!targetPath.startsWith("/")) {
          targetPath = "/" + targetPath;
        }

        // 슬래시(`/`)로 끝나지 않도록 처리하여 비교 시 일관성 유지
        targetPath = targetPath.replace(/\/$/, "");

        // 현재 경로가 targetPath로 시작하면 active 처리
        const isActive =
          currentPath === targetPath ||
          currentPath.startsWith(targetPath + "/");

        // 서브메뉴가 없는 일반 메뉴 처리
        if (page.url === "/group-purchase") {
          return (
            <li key={pageIdx}>
              <Link href={page.url}>
                <a className={isActive ? "active" : ""}>
                  <div className="position-relative">
                    <ProceedingMenuBadge siteInfo={siteInfo} />
                    {page.title}
                  </div>
                </a>
              </Link>
            </li>
          );
        } else {
          return (
            <li key={pageIdx}>
              <Link href={page.url}>
                <a className={isActive ? "active" : ""}>{page.title}</a>
              </Link>
            </li>
          );
        }
      } else {
        // 맞춤제작 메뉴 특별 처리
        if (page.title === "맞춤제작") {
          // siteInfo.MakeCategory에서 데이터를 가져와 동적으로 구성
          const categories = siteInfo?.MakeCategory || [];

          // PageRouter에서 맞춤제작 관련 정적 메뉴 항목 찾기
          const customizingRouter = PageRouter.find(
            (route) => route.title === "맞춤제작"
          );

          // 하단 메뉴용 항목 (견적 바구니, 포트폴리오, 고객사)
          const bottomMenuItems = customizingRouter.sub;

          // 기타 메뉴 항목들 (시제품 제작, 판촉물 제작)
          const otherMenuItems = bottomMenuItems.filter((item) => {
            // siteInfo 값에 따라 필터링
            if (
              item.url === "/customizing/prototype" &&
              siteInfo?.Prototype === null
            ) {
              return false;
            }
            if (
              item.url === "/customizing/promotion" &&
              siteInfo?.Promotion === null
            ) {
              return false;
            }
            return true;
          });

          return (
            <li key={pageIdx} className="menu-item-has-children custom-menu">
              <Link href={page.url || "#"}>
                <a
                  className={
                    page.sub.some(
                      (subItem) =>
                        router.asPath.includes(subItem.url) ||
                        (subItem.subCategories &&
                          subItem.subCategories.some((cat) =>
                            router.asPath.includes(cat.url)
                          ))
                    )
                      ? "active"
                      : ""
                  }
                >
                  {page.title}
                </a>
              </Link>

              <div className="mega-menu">
                <div className="mega-menu-wrapper grid-layout">
                  <p>포장재 카테고리</p>
                  <hr class="underbar" />
                  {/* 메인 카테고리 영역 - 가로 스크롤 */}
                  <div className="mega-menu-grid">
                    {/* 기타 메뉴 항목들 (시제품 제작, 판촉물 제작 등) */}
                    {otherMenuItems.length > 0 && (
                      <div className="left-menu-section">
                        <ul className="mega-menu-items">
                          {otherMenuItems.map((item, idx) => (
                            <li key={idx}>
                              <Link href={item.url || "#"}>
                                <a
                                  className={
                                    router.asPath.includes(item.url)
                                      ? "active"
                                      : ""
                                  }
                                >
                                  {item.title}
                                </a>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    <div className="cart-button-container">
                      <Link
                        href={
                          "https://www.caretstore.co.kr/customizing/customizing-cart/"
                        }
                      >
                        <a className="estimate-cart-button">견적 바구니</a>
                      </Link>
                    </div>

                    {/* 동적으로 생성된 카테고리 메뉴들 */}
                    {categories.map((category, catIdx) => {
                      return (
                        <div key={catIdx} className="grid-menu-section">
                          <h4 className="mega-menu-title">
                            <Link
                              href={`/customizing/customizing-products/new/?first=${category.code}&code=`}
                            >
                              <a>{category.name}</a>
                            </Link>
                          </h4>
                          <ul className="mega-menu-items">
                            {category.children?.map((child, childIdx) => {
                              return (
                                <li key={childIdx}>
                                  <Link
                                    href={`/customizing/customizing-products/new/?first=${category.code}&code=${child.code}`}
                                  >
                                    <a>{child.name}</a>
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                  {/* 배너 이미지 추가 */}
                  {headerMenu &&
                    headerMenu.length > 0 &&
                    headerMenu[0]?.P_FileUrl && (
                      <button
                        className="mega-menu-banner border-0"
                        onClick={() => {
                          const url =
                            (headerMenu &&
                              headerMenu.length > 0 &&
                              headerMenu[0]?.link_url) ||
                            "https://www.caretstore.co.kr/";
                          router.push(url);
                        }}
                      >
                        <HeaderImageBanner
                          imageUrl={
                            headerMenu[0].P_FileUrl || "img/Purchase_Banner.png"
                          }
                          altText={headerMenu[0].img_alt || ""}
                        />
                      </button>
                    )}
                </div>
              </div>
            </li>
          );
        } else {
          // 일반적인 서브메뉴가 있는 메뉴 처리
          return (
            <li key={pageIdx} className="menu-item-has-children">
              {page.url ? (
                <Link href={page.url}>
                  <a
                    className={
                      page.sub.some((subItem) =>
                        router.asPath.includes(subItem.url)
                      )
                        ? "active"
                        : ""
                    }
                  >
                    {page.title}
                  </a>
                </Link>
              ) : (
                <a
                  role="button"
                  className={
                    page.sub.some((subItem) =>
                      router.asPath.includes(subItem.url)
                    )
                      ? "active"
                      : ""
                  }
                >
                  {page.title}
                </a>
              )}

              <ul className="sub-menu">
                {page.sub.map((subItem, subIdx) => (
                  <li key={subIdx}>
                    <Link href={subItem.url || "#"}>
                      <a
                        className={
                          router.asPath.includes(subItem.url) ? "active" : ""
                        }
                      >
                        {subItem.title}
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          );
        }
      }
    });

    return menus;
  };

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY >= 100;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    });

    document.addEventListener("mouseover", hadleClickOutSide);
    return () => {
      document.removeEventListener("mouseover", hadleClickOutSide);
    };
  }, []);

  useEffect(() => {
    if (mainAward && mainAward.length > 0) {
      setTimeout(() => {
        if (awardIdx + 1 >= mainAward.length) {
          setAwardIdx(0);
          return;
        }
        setAwardIdx((prev) => prev + 1);
      }, 3000);
    }
  }, [bannerList, awardIdx]);

  useEffect(() => {
    getPushStats();
    isTokenValid();
    showBtnText();
    // queryClient.invalidateQueries({
    //   queryKey: [QUERY_KEYS.SETTING.BANNERS.INDEX],
    // });
  }, [userInfo]);

  useEffect(() => {
    if (!router.isReady) return;
    setMenus(buildPage(PageRouter));
    getCookie("token") && fetchBasketsAction();
    if (Object.keys(dummyList).length === 0) {
      fetchDummyList();
    }
    if (Object.keys(siteInfo).length === 0) {
      fetchSiteInfo(router);
    }
    if (typeof window !== "undefined" && "Notification" in window) {
      // 알림 허용 상태 변경 시 실행되는 함수
      const handlePermissionChange = () => {
        setNotificationPermission(Notification.permission);
      };

      // 알림 허용 상태 변경 이벤트를 감지하고 상태를 업데이트합니다.
      Notification.requestPermission().then(handlePermissionChange);
      window.addEventListener("change", handlePermissionChange);

      // 컴포넌트 언마운트 시 이벤트 리스너를 제거합니다.
      return () => {
        window.removeEventListener("change", handlePermissionChange);
      };
    }
  }, [router.isReady, siteInfo]);

  const bannerCookie = getCookie(BANNER_COOKIE_NAME);

  return (
    <Suspense>
      <header className="header-area header-style-1 header-height-2">
        {/* 이미지 배너 또는 띠 배너 */}
        {isMobile ? (
          Array.isArray(mainStripe) &&
          mainStripe.length > 0 && <StripeBanner banners={bannerList} />
        ) : bannerCookie ? (
          <StripeBanner banners={bannerList} />
        ) : (
          Array.isArray(mainImageStripe) &&
          mainImageStripe.length > 0 && (
            <ImageBanner banners={bannerList} handleClose={handleClose} />
          )
        )}
        {/* 최상단 헤더 */}
        <FirstHeader
          logo={logo}
          banners={bannerList}
          awardIdx={awardIdx}
          scroll={scroll}
          openSearch={openSearch}
          setOpenSearch={setOpenSearch}
          scrollSearchBtnRef={scrollSearchBtnRef}
        />
        {/* 2번 헤더 */}
        <SecondHeader
          scroll={scroll}
          logo={logo}
          banners={bannerList}
          categoriRef={categoriRef}
          setToggled={setToggled}
          menus={menus}
          decideUrl={decideUrl}
          userName={userName}
          userCompanyName={userCompanyName}
          isUser={isUser}
          authHandler={authHandler}
          getCookie={getCookie}
          basketsCount={basketsCount}
          toggleClick={toggleClick}
          userInfo={userInfo}
          userCoupons={userCoupons}
          notificationPermission={notificationPermission}
          onClickToggleBtn={onClickToggleBtn}
          totalCartItems={totalCartItems}
          openSearch={openSearch}
          setOpenSearch={setOpenSearch}
          scrollSearchBtnRef={scrollSearchBtnRef}
          isToggleActive={isToggleActive}
        />
      </header>
      <MobileSearch
        searchToggled={searchToggled}
        onClickSearch={onClickSearch}
        dummyList={dummyList}
      />
      <UserFloating
        scroll={scroll}
        userInfo={userInfo}
        basketItems={basketItems}
        clearBasketsAction={clearBasketsAction}
        modifyConfirmModal={modifyConfirmModal}
        openConfirmModal={openConfirmModal}
        closeConfirmModal={closeConfirmModal}
        deleteBasketsAction={deleteBasketsAction}
        fetchBasketsAction={fetchBasketsAction}
        insertBasketsAction={insertBasketsAction}
        deleteFromCart={deleteFromCart}
        vp={vp}
        cartItems={cartItems}
        clearCart={clearCart}
        decreaseCnt={decreaseCnt}
        increaseCnt={increaseCnt}
        onClickSearch={onClickSearch}
        toggleClick={toggleClick}
      />
    </Suspense>
  );
};

const mapStateToProps = (state) => ({
  show: state.confirm.modal,
  totalCartItems: state.cart.length,
  cartItems: state.cart,
  basketItems: state.baskets.items,
  basketsCount: state.baskets.count,
  productFilters: state.productFilters,
  loading: state.loading.loading,
  // banners: state.banners,
  dummyList: state.dummyList,
  vp: state.vp,
  siteInfo: state.siteInfo,
});

const mapDispatchToProps = {
  openConfirmModal,
  modifyConfirmModal,
  closeConfirmModal,
  deleteBasketsAction,
  insertBasketsAction,
  fetchBasketsAction,
  clearBasketsAction,
  initBaskets,
  deleteFromCart,
  updateProductCategory,
  isLoaded,
  isLoading,
  fetchDummyList,
  clearCart,
  decreaseCnt,
  increaseCnt,
  fetchSiteInfo,
  initUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
