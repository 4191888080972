import styled from "styled-components";

export const SeacrhSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0px;
  @media (max-width: 1200px) {
    padding: 0px 20px;
  }
`;

export const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  .search_icon {
    width: fit-content;
    height: 100%;
    display: flex;
    align-items: center;
    & img {
      width: 50px;
    }
  }
  @media (max-width: 1200px) {
    .search_icon {
      & img {
        width: 40px;
      }
    }
  }
`;

export const HeaderSearch = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid #bce3c9;
  border-radius: 5px;
  margin-right: 10px;
  min-width: 180px;
  padding: 10px 20px;
  & input {
    width: 100%;
    max-height: 30px;
    border: none;
    padding: 0px;
  }
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const SizeSearchWrapper = styled.div`
  display: flex;
  gap: 10px;
  border: 2px solid #bce3c9;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  padding: 10px 20px;
  margin-right: 10px;
  @media (max-width: 1200px) {
    display: none;
  }
`;

export const SizeInputBox = styled.div`
  display: flex;
  width: 100px;
  position: relative;
  padding: 0px 40px 0px 0px;
  height: 100%;
  & input {
    border: none;
    border-radius: 0%;
    height: 100%;
    padding: 0px;
    color: #253d4e;
    font-size: 16px;
    width: 40px;
  }
  ::after {
    content: "mm";
    position: absolute;
    right: 0px;
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #253d4e;
    font-size: 16px;
  }

  @media (max-width: 1200px) {
    width: 160px;
  }
`;

export const SizeRangeSelect = styled.label`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 0px;

  ::before {
    content: "";
    position: absolute;
    top: 15%;
    left: 0px;
    width: 0px;
    height: 70%;
    border-left: 1px solid #ccc;
  }
  & select {
    width: 100%;
    padding: 0px 16px;
    cursor: pointer;
  }
  ::after {
    font-family: uicons-regular-straight !important;
    font-weight: normal !important;
    content: "\f131";
    position: absolute;
    right: 0px;
    width: 16px;
    font-size: 16px;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: -1;
  }
  @media (max-width: 1200px) {
    width: 200px;
  }
`;

export const SwiperRank = styled.div`
  width: 100%;
  height: fit-content;
  color: #1b1b1b;
  text-align: start;
`;

export const RankBox = styled.div`
  position: absolute;
  top: 0;
  width: fit-content;
  min-width: 278px;
  height: auto;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  display: none;
  z-index: 100;
  padding: 20px;
  flex-direction: column;

  & h6 {
    font-size: 16px;
    color: #1b1b1b;
  }
`;

export const SeacrhTags = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: -25px;
  font-size: 13px;
  & a {
    color: #7e7e7e;
    position: relative;
    text-decoration: none;
    margin-right: 10px;
    :last-of-type {
      margin-right: 0px;
    }
    ::after {
      content: "";
      position: absolute;
      width: 0;
      left: 50%;
      bottom: 0;
      border-bottom: 1px solid #3bb77e;
      transition: width 0.2s, left 0.2s;
      -webkit-transition: width 0.2s, left 0.2s ease;
    }

    :hover {
      color: #3bb77e !important;
      ::after {
        width: 100%;
        left: 0;
      }
    }
  }
`;

export const ScrollSearch = styled.div`
  display: flex;
  width: 20%;
  position: relative;
  margin-right: 30px;
  z-index: 10;
  > form {
    margin-right: 0;
    height: 45px;
    position: absolute;
    border: none !important;
    border-radius: 10px;
    width: 0px;
    right: 20px;
    top: -30%;
    font-size: 0px !important;
    transition: width 0.3s ease-in-out;
    display: flex;
    align-items: center;
    & i {
      cursor: pointer;
    }
  }
  .active {
    z-index: 50;
    width: 250px;
    border: 2px solid #bce3c9 !important;
    font-size: 1.1rem !important;
    & i {
      cursor: pointer;
    }
  }

  & input {
    height: 30px !important;
    font-size: 14px;
    background-color: #fff !important;
    border: none !important;
    padding: 0 0 0 10px;
  }

  @media (max-width: 1440px) {
    width: 20%;
    margin-right: -40px;
  }
  @media (max-width: 1200px) {
    display: none;
  }
`;

export const SeachIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #121212;
  font-size: 23px;
  cursor: pointer;
  z-index: 50;
  > i {
    margin-top: 2px;
  }
`;

export const FloatingSearchWrapper = styled.div`
  position: ${(props) => (props.$scroll ? "fixed" : "absolute")};
  top: ${(props) => (props.$scroll ? "124px" : "55px")};
  left: ${(props) => (props.$scroll ? "" : "0px")};
  width: 615px;
  display: ${(props) => (props.open ? "flex" : "none")};
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  z-index: 100;
  & h6 {
    font-size: 16px;
    font-weight: 700;
  }
  @media (max-width: 1200px) {
    top: ${(props) => (props.$scroll ? "124px" : "55px")};
    left: ${(props) => (props.$scroll ? "" : "20px")};
    top: ${(props) => (props.$scroll ? "160px" : "55px")};
    width: 550px;
  }
`;

export const FloatingScrolledSearchBar = styled.div`
  display: flex;
  position: fixed;
  top: 73px;
  z-index: 100;
  width: 615px;
  border: 2px solid #bce3c9;
  border-radius: 5px;
  background: #fff;

  & input {
    width: 100%;
    border: none;
  }
  @media (max-width: 1200px) {
    top: 108px;
    width: 550px;
  }
`;

export const FloatingHeader = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e1e1e1;
  padding: 15px 20px;
  @media (max-width: 1200px) {
    border-bottom: 0px;
  }
`;

export const HeaderTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & i {
    ::before {
      font-weight: 700 !important;
    }
  }
`;

export const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  margin-top: 10px;
  row-gap: 8px;

  & a {
    margin-right: 10px;
    color: #fff !important;
    background-color: #3bb77e;
    padding: 5px 10px;
    border-radius: 10px;
  }
`;

export const FloatingSizeSearch = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
  border-top: 2px solid #bce3c9;
  border-bottom: 2px solid #bce3c9;
  display: none;

  .size_input_wrapper {
    display: flex;
    gap: 10px;
    width: calc(100% - 70px);
    padding: 0px 10px;
  }
  .search_icon {
    width: 40px;
    display: flex;
    & img {
      width: 40px;
    }
  }
  @media (max-width: 1200px) {
    display: flex;
  }
`;

export const FloatingBody = styled.div`
  display: grid;
  grid-template-columns: repeat(11, 1fr);

  & a {
    color: #5b5b5b !important;
    position: relative;
    text-decoration: none;

    :hover {
      color: #3bb77e !important;
    }

    ::after {
      content: "";
      position: absolute;
      width: 0;
      left: 50%;
      bottom: 0;
      border-bottom: 1px solid #3bb77e;
      transition: width 0.2s, left 0.2s;
      -webkit-transition: width 0.2s, left 0.2s ease;
    }

    :hover::after {
      width: 100%;
      left: 0;
    }
  }
`;

export const RecentSearch = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  grid-column: 6 span / 6 span;
  border-right: 1px solid #e1e1e1;
`;

export const RecentList = styled.div`
  margin-top: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const RecentRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  & figure {
    margin-right: 5px;
    display: inline-flex;
  }

  .delete {
    cursor: pointer;
    display: flex;
    font-size: 12px;
    margin-left: auto;
    padding-top: 1px;
    :hover {
      color: #dc3545;
    }
  }

  & a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const RecentDeleteAll = styled.div`
  color: #b0b0b0;
  width: fit-content;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  margin-top: auto;
  ::after {
    content: "";
    position: absolute;
    width: 100%;
    left: 0%;
    bottom: 0;
    border-bottom: 1px solid #b0b0b0;
  }
  :hover {
    color: #dc3545;
    ::after {
      border-color: #dc3545;
    }
  }
`;

export const RankSearch = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  grid-column: 5 span / 5 span;
`;

export const RankList = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
`;

export const RankRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  :last-of-type {
    margin-bottom: 0px;
  }

  .number {
    min-width: 20px;
    font-size: 16px;
    font-weight: 800;
    color: #3bb77e;
    margin-right: 5px;
    display: flex;
    justify-content: end;
    line-height: 26px;
  }

  & a {
    color: #000000 !important;
    font-size: 14px;
    margin-right: 5px;
    line-height: 16.94px;
    white-space: nowrap;
  }

  .desc {
    color: #acacac;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.73px;
    padding-top: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
