import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  background-color: white;
  border: 1px solid #ececec;
  border-radius: 15px;
  transition: 0.2s;
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  overflow: hidden;
  margin-bottom: 5px;

  :hover {
    -webkit-box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);
    border: 1px solid #bce3c9;
    transition: 0.2s;
    -moz-transition: 0.2s;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
  }
`;

export const ProductImgWrapper = styled.div`
  position: relative;
  background-color: #fff;
  overflow: hidden;
`;

export const ProductImg = styled.a`
  overflow: hidden;

  & img {
    -webkit-transition: all 1s cubic-bezier(0, 0, 0.05, 1);
    transition: all 1s cubic-bezier(0, 0, 0.05, 1);
    :hover {
      transform: scale3d(1.03, 1.03, 1.03) translateZ(0);
    }
  }
`;

export const SfnzBadge = styled.span`
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 20;
`;

// 컨텐츠 부분
export const ProductContentWrapper = styled.div`
  padding: 0 10px 15px 10px;
  //background: #0c4128;
`;

export const ProductName = styled.a`
  width: 100%;
  height: 3rem;
  font-size: 16px;
  font-weight: 500;
  color: #253d4e;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  :hover {
    color: #3bb77e;
  }
  @media (max-width: 760px) {
    font-size: 1rem;
  }
`;

// 하단 부분
export const ProductBottomWrapper = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 5px 0;
`;

export const PriceWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const PriceBox = styled.div`
  width: 100%;
  height: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;

  & > div {
    span:first-child {
      color: #dc3545;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin-right: 0.2rem;
    }
    span:last-child {
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      text-decoration: line-through;
      color: #6c757d;
      opacity: 0.5;
      padding: 0;
      margin: 0;
    }
  }

  @media (max-width: 480px) {
    width: 100%;
    height: 2rem;
    margin-bottom: 0.5rem;
    & h5 {
      font-size: 16px;
    }
    & > div {
      span:first-child {
        font-size: 1rem;
        color: #dc3545;
      }
      span:last-child {
        padding: 0;
        margin: 0;
      }
    }
  }
`;

export const CartIconBox = styled.div`
  font-size: 1.5rem;
  position: relative;
  display: inline-block;
  font-weight: 700;
  color: #3bb77e;

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }

  & span {
    width: 2.5rem;
    display: flex;
    justify-content: flex-end;
    font-size: 1rem;
    color: red;
    font-weight: 500;
  }

  & i {
    :hover {
      color: #ffc107;
      -webkit-box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);
      box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);
    }
  }
`;

export const StickerWrapper = styled.div`
  width: 100%;
  height: 1rem;
  margin-top: 10px;
  padding-top: 0.7rem;
  border-top: 1px solid #ececec;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ViewBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-size: 0.8rem;

  & div {
    display: flex;
    align-items: center;
  }

  & img {
    width: 1rem;
    margin-left: 5px;
    margin-right: 0.5rem;
  }
`;

export const Sticker = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  font-size: 12px;
  color: white;
  background-color: #3bb77e;
  border: 1px solid white;
  border-radius: 2rem;
`;
