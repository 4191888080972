import styled from "styled-components";

export const ScrollTopBtn = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: fixed;
  bottom: 20px;
  right: 23px;
  z-index: 999;
  cursor: pointer;
  @media (max-width: 991px) {
    right: 13px;
    bottom: 135px;
    width: 44px;
    height: 44px;
  }
`;

// 기존 FooterBodySection에 상하 테두리 관련 변경
export const FooterBodySection = styled.section`
  width: 100%;
  position: relative;
  border: 1px solid #ececec;

  @media (max-width: 1199px) {
    display: none;
  }
`;

export const FooterBottomWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 50px;

  @media (max-width: 1199px) {
    flex-wrap: wrap;
  }
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

export const FooterBottomCol = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & p {
    margin: 0;
  }

  & li {
    width: fit-content;
    display: flex;
    align-items: center;
  }

  & h6 {
    margin-right: 5px;
  }

  & span {
    word-break: keep-all;
    margin-right: 5px;
  }

  & a {
    width: 250px;
  }

  @media (max-width: 1199px) {
    width: 100%;
  }
`;

export const MobileSocialIconWrapper = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-item-align: center;
  align-self: center;

  & h6 {
    display: inline-block;
    margin-right: 15px;
  }
  & a {
    text-align: center;
    font-size: 14px;
    margin-right: 5px;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    height: 40px;
    width: 40px !important;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    background: #3bb77e;
    border-radius: 30px;
    line-height: 1;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    align-items: center;
    :hover {
      opacity: 0.6;
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px);
      -webkit-transition-duration: 0.5s;
      transition-duration: 0.5s;
      margin-top: -2px;
    }
  }
  & img {
    max-width: 20px;
    max-height: 20px;
  }
`;

export const FooterCopyWrapper = styled.div`
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
`;

// 푸터 전체 컨테이너에 상하 테두리 추가
export const FooterContainer = styled.footer`
  width: 100%;
`;

export const MobileFooterBodySection = styled.section`
  display: none;
  width: 100%;
  position: relative;
  border: 1px solid #ececec;

  @media (max-width: 1199px) {
    display: flex;
  }
`;

export const MobileListWrapper = styled.ul`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin-bottom: 20px;
  & li {
    display: flex;
    justify-content: center;
    align-items: center;
    & a {
      color: #696969;
      font-size: 14px;
    }
  }
  @media (max-width: 354px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    & li {
      & a {
        font-size: 14px;
      }
    }
  }
`;

export const MobileContactInfo = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  & li {
    display: flex;
    align-items: center;
    & h6 {
      color: #696969;
      margin-right: 10px;
      font-size: 16px;
      white-space: nowrap;
    }
    & span {
      color: #696969;
      font-size: 16px;
      word-break: keep-all;
    }
  }
  @media (max-width: 575px) {
    & li {
      & h6 {
        font-size: 14px;
      }
      & span {
        color: #696969;
        font-size: 14px;
        word-break: keep-all;
      }
    }
  }
`;
