import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "@common/queryKeys";
import { listSetBanners } from "@api/banners";
import useBannerStore from "../store/useBannerStore";
import { useEffect } from "react";

// 배너 목록을 가져오는 커스텀 훅
export const useBannerQuery = () => {
  // Zustand 스토어에서 상태와 액션 가져오기
  const setBannerData = useBannerStore((state) => state.setBannerData);
  const storedBannerData = {
    headerMenu: useBannerStore((state) => state.headerMenu),
    mainAward: useBannerStore((state) => state.mainAward),
    mainStripe: useBannerStore((state) => state.mainStripe),
    mainImageStripe: useBannerStore((state) => state.mainImageStripe),
    customSm: useBannerStore((state) => state.customSm),
    customLg: useBannerStore((state) => state.customLg),
    interview: useBannerStore((state) => state.interview),
    mainBtmM: useBannerStore((state) => state.mainBtmM),
    mainBtmS: useBannerStore((state) => state.mainBtmS),
    mainBtmThirdCenter: useBannerStore((state) => state.mainBtmThirdCenter),
    mainBtmThirdLeft: useBannerStore((state) => state.mainBtmThirdLeft),
    mainBtmThirdRight: useBannerStore((state) => state.mainBtmThirdRight),
    mainEventStripe: useBannerStore((state) => state.mainEventStripe),
    mainSide: useBannerStore((state) => state.mainSide),
    mainTop: useBannerStore((state) => state.mainTop),
    mainTopCenterEvent: useBannerStore((state) => state.mainTopCenterEvent),
    mainTopEvent: useBannerStore((state) => state.mainTopEvent),
    mobileBtm: useBannerStore((state) => state.mobileBtm),
    prodLeftBtm: useBannerStore((state) => state.prodLeftBtm),
  };

  // React Query로 데이터 가져오기
  const { data, refetch, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.SETTING.BANNERS.INDEX],
    queryFn: async () => {
      const res = await listSetBanners({ modes: "ListSet" });

      if (res.Code === "0000") {
        return res?.Data?.rows ?? {}; // 데이터가 없을 경우 빈 객체 반환
      }
      throw new Error(res.Data?.Msg || "데이터 조회 실패");
    },
    enabled: true,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    staleTime: 0,
    cacheTime: 1000 * 60 * 30, // 30분 동안 캐시 유지
    onError: (error) => {
      console.error("목록 조회 실패:", error);
    },
  });

  // 데이터가 로드되면 Zustand 스토어에 저장
  useEffect(() => {
    if (data) {
      const bannerData = {
        headerMenu: data?.header_menu ?? [],
        mainAward: data?.main_award ?? [],
        mainStripe: data?.main_stripe ?? [],
        mainImageStripe: data?.main_image_stripe ?? [],
        customSm: data?.custom_sm ?? [],
        customLg: data?.custom_lg ?? [],
        interview: data?.interview ?? [],
        mainBtmM: data?.main_btm_m ?? [],
        mainBtmS: data?.main_btm_s ?? [],
        mainBtmThirdCenter: data?.main_btm_third_center ?? [],
        mainBtmThirdLeft: data?.main_btm_third_left ?? [],
        mainBtmThirdRight: data?.main_btm_third_right ?? [],
        mainEventStripe: data?.main_event_stripe ?? [],
        mainSide: data?.main_side ?? [],
        mainTop: data?.main_top ?? [],
        mainTopCenterEvent: data?.main_top_center_event ?? [],
        mainTopEvent: data?.main_top_event ?? [],
        mobileBtm: data?.mobile_btm ?? [],
        prodLeftBtm: data?.prod_left_btm ?? [],
      };
      setBannerData(bannerData);
    }
  }, [data, setBannerData]);

  // Zustand 스토어의 데이터를 우선적으로 반환하고, API 데이터 동기화 중
  return {
    bannerList: data,
    // 전역 상태의 데이터 반환
    headerMenu: storedBannerData.headerMenu,
    mainAward: storedBannerData.mainAward,
    mainStripe: storedBannerData.mainStripe,
    mainImageStripe: storedBannerData.mainImageStripe,
    customSm: storedBannerData.customSm,
    customLg: storedBannerData.customSm,
    interview: storedBannerData.interview,
    mainBtmM: storedBannerData.mainBtmM,
    mainBtmS: storedBannerData.mainBtmS,
    mainBtmThirdCenter: storedBannerData.mainBtmThirdCenter,
    mainBtmThirdLeft: storedBannerData.mainBtmThirdLeft,
    mainBtmThirdRight: storedBannerData.mainBtmThirdRight,
    mainEventStripe: storedBannerData.mainEventStripe,
    mainSide: storedBannerData.mainSide,
    mainTop: storedBannerData.mainTop,
    mainTopCenterEvent: storedBannerData.mainTopCenterEvent,
    mainTopEvent: storedBannerData.mainTopEvent,
    mobileBtm: storedBannerData.mobileBtm,
    prodLeftBtm: storedBannerData.prodLeftBtm,
    isFetching,
    refetch,
  };
};
