export const PageRouter = [
  {
    url: "/products?code=01",
    title: "상품구매",
  },
  {
    url: "/customizing/customizing-products",
    title: "맞춤제작",
    sub: [
      // {
      //   url: "/customizing/customizing-products",
      //   title: "포장재 제작",
      // },
      {
        url: "/customizing/prototype",
        title: "시제품 제작",
      },
      {
        url: "/customizing/promotion",
        title: "판촉물 제작",
      },
      {
        url: "/customizing/design-service",
        title: "디자인 서비스",
      },
      {
        url: "/customizing/portfolio",
        title: "포트폴리오",
      },
      {
        url: "/partners",
        title: "고객사",
      },
    ],
  },
  {
    url: "/customizing/print",
    title: "소량인쇄",
    // sub: [
    //   {
    //     url: "/edu",
    //     title: "적정포장 무료교육",
    //   },
    // ],
  },
  {
    url: "/group-purchase",
    title: "공동구매",
  },
  {
    url: "/esg-guide",
    title: "ESG 자료실",
    sub: [
      {
        url: "/esg-guide",
        title: "ESG",
      },
      {
        url: "/esg-guide/news",
        title: "ESG 환경뉴스",
      },
      {
        url: "/esg-guide/letter",
        title: "ESG 뉴스레터",
      },
      {
        url: "/esg-guide/blog/package",
        title: "소재와 포장재",
      },
      {
        url: "/esg-guide/blog/make",
        title: "제작사례 & 포장재리뷰",
      },
    ],
  },
  {
    url: "",
    title: "고객지원",
    sub: [
      {
        url: "/csboard/notice",
        title: "공지사항",
      },
      {
        url: "/csboard/faq",
        title: "FAQ",
      },
      {
        url: "/csboard/library",
        title: "상품소개서 다운로드",
      },
      {
        url: "/csboard/contact",
        title: "제휴문의",
      },
      {
        url: "/csboard/b2b",
        title: "패키징 제안받기",
      },
      {
        url: "/csboard/service",
        title: "구해드립니다",
      },
    ],
  },
];
