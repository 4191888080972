import styled, { keyframes } from "styled-components";

export const CategoryWrapper = styled.div`
  color: #fff;
  border-radius: 5px;
  min-width: 180px;

  .main_category_button {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    color: #fff;
    background: #3bb77e;
    padding: 10px 10px;
    cursor: pointer;
    line-height: normal;
    font-size: 1rem;
    font-weight: 500;
    & i {
      font-size: 1rem !important;
      color: #fff;
      padding-top: 2px;
    }
    :hover {
      opacity: 0.8;
      .fi-rs-angle-down {
        transform: rotate(0.5turn);
      }
    }
  }

  @media screen and (max-width: 1440px) {
    margin-right: 30px;
  }
`;

export const NotificationArea = styled.li`
  display: flex;
  align-items: center;
  color: #212529;

  .btn {
    margin-right: 0 auto;
  }
`;

export const MobileNotificationArea = styled.div`
  font-size: 14px;
  color: #212529;

  i {
    margin-top: 2px;
  }

  span {
    margin-right: 8px;
  }

  .btn {
    margin-right: 0 auto;
  }
`;

export const MenuNavigation = styled.div`
  padding: 20px 0;
  word-break: keep-all;
  width: fit-content;

  > nav > ul > li {
    margin-right: 20px;
    line-height: 30px !important;
    padding: 0 !important;
    > a {
      font-size: 1.1rem !important;
    }
  }

  .special {
    border: 1px solid purple;
    background-color: purple;
    border-radius: 10px;
    padding: 0px 10px;
    color: white !important;
  }
`;

export const MobileCateBtn = styled.a`
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 14px;
  background-color: #3bb77e;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 5px;
  & i {
    margin-right: 5px;
  }
  :hover {
    color: #fff;
  }
`;

export const MobileCategoryWrapper = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid #f2f3f4;
`;

export const CouponInfo = styled.li`
  position: relative;
  .list {
    display: none;
    position: absolute;
    top: 50%;
    right: 120%;
    background-color: #fff;
    z-index: 99999;
    padding: 10px;
    border: 1px solid #3bb77e;
    border-radius: 10px;
    width: 250px;
    word-break: keep-all;
    line-height: 1.5;
    .limit {
      font-size: 0.8rem;
    }
  }
  :hover {
    .list {
      display: block;
      > div {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #ececec;
        margin-bottom: 5px;
        padding-bottom: 5px;
        :last-of-type {
          border-bottom: none;
          margin-bottom: 0px;
        }
      }
    }
  }
`;

export const StripeBannerWrapper = styled.div`
  background-color: #1d1d1d;

  @media screen and (min-width: 575px) {
    .swiper-wrapper {
      transition-timing-function: linear;
    }
  }
`;

export const StripeBanner = styled.div`
  height: 42px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  & a {
    color: #fff;
    position: relative;
    text-decoration: none;

    ::after {
      content: "";
      position: absolute;
      width: 0;
      left: 50%;
      bottom: 0;
      border-bottom: 1px solid #fff;
      transition: width 0.2s, left 0.2s;
      -webkit-transition: width 0.2s, left 0.2s ease;
    }

    :hover::after {
      width: 100%;
      left: 0;
    }
  }
`;

// 중간 헤더 (로고, 검색, 고객센터)
export const TopHeader = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 991px) {
    display: none;
  }
`;

export const LogoWrapper = styled.div`
  width: 20%;
  max-width: 300px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  & a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pc {
    position: absolute;
    transition: 0.3s ease-in-out;
    transform: translateY(0px);
    opacity: 1;
  }
  .over {
    position: absolute;
    transition: 0.3s ease-in-out;
    opacity: 0;
    transform: translateY(10px) rotateX(90deg);
  }
  :hover {
    .pc {
      transform: translateY(-20px) rotateX(-90deg);
      opacity: 0;
    }
    .over {
      transform: translateY(0px) rotateX(0deg);
      opacity: 1;
    }
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  width: calc(80% - 170px);
  max-width: 655px;
`;

const SlidesIn = keyframes`
  from{
    opacity: 0;
	}
	to{
    opacity: 1;
	}
`;

export const AwardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 95px;
  & img {
    max-width: 100%;
    animation: ${SlidesIn} 0.3s linear normal;
  }
`;

export const SearchIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #121212;
  font-size: 23px;
  z-index: 50;
  width: 53px;
  padding-right: 8px;
  > i {
    cursor: pointer;
    margin-top: 2px;
  }
`;

const BlinkEffects = keyframes`
  50% {
    opacity: 0;
  }
`;

export const CouponBadge = styled.div`
  position: absolute;
  left: -5px;
  bottom: -10px;
  height: 18px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
  border: 1px solid #fd7e14;
  border-radius: 10px;
  color: #fd7e14;
  background-color: #fff;
  font-size: 11px;
  font-weight: 300;
  animation: ${BlinkEffects} 1s step-end infinite;
`;

// 메뉴 아이템 스타일
export const MenuItem = styled.li`
  display: inline-block;
  position: relative;

  &.custom-menu {
    position: static;
  }

  &:hover > a {
    color: #3bb77e;
  }
`;

// 메인 메뉴 컨테이너
export const MainMenuContainer = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  position: static;
`;

// 메뉴 링크
export const MenuLink = styled.a`
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #253d4e;
  transition: all 0.3s ease;
`;

// 메가 메뉴 컨테이너
export const MegaMenu = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 10px 10px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 1000;
  padding: 35px 25px;
  border: 1px solid #ececec;
  margin-top: 20px;

  ${MenuItem}.custom-menu:hover & {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
  }
`;

// 메가 메뉴 래퍼
export const MegaMenuWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
`;

// 메가 메뉴 컬럼
export const MegaMenuColumn = styled.div`
  flex: 0 0 20%;
  padding: 0 15px;
  margin-bottom: 15px;

  &:first-child {
    flex: 0 0 40%;
  }

  @media (max-width: 991px) {
    flex: 0 0 100%;
    &:first-child {
      flex: 0 0 100%;
    }
  }
`;

// 메가 메뉴 타이틀
export const MegaMenuTitle = styled.h4`
  font-size: 16px;
  color: #333;
  font-weight: 700;
  margin: 0 0 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;

  a {
    color: #333;
    text-decoration: none;
    transition: all 0.3s ease;

    &:hover {
      color: #3bb77e;
    }
  }
`;

// 메가 메뉴 아이템 리스트
export const MegaMenuItems = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 28px;

  ${MegaMenuColumn}:first-child & {
    column-count: 2;
    column-gap: 15px;
  }

  @media (max-width: 991px) {
    ${MegaMenuColumn}:first-child & {
      column-count: 1;
    }
  }
`;

// 메가 메뉴 아이템
export const MegaMenuItem = styled.li`
  margin-bottom: 14px;
  break-inside: avoid;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    font-size: 15px;
    color: #7e7e7e;
    display: block;
    line-height: 1.4;
    transition: all 0.3s ease;

    &:hover {
      color: #3bb77e;
      padding-left: 5px;
    }

    &.active {
      color: #3bb77e;
    }
  }
`;

// 일반 서브메뉴
export const SubMenu = styled.ul`
  position: absolute;
  left: 0;
  top: 65%;
  background-color: #ffffff;
  min-width: 250px;
  padding: 25px 15px;
  transition: all 0.25s ease 0s;
  opacity: 0;
  visibility: hidden;
  margin-top: 20px;
  border-radius: 10px;
  z-index: 999;
  border: 1px solid #ececec;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);

  ${MenuItem}:not(.custom-menu):hover & {
    opacity: 1;
    visibility: visible;
    margin-top: 12px;
  }
`;

// 서브메뉴 아이템
export const SubMenuItem = styled.li`
  line-height: 1;
  display: block;
  margin-bottom: 21px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    font-size: 14px;
    color: #7e7e7e;
    display: block;
    padding: 0 15px;
    transition: all 0.3s ease;

    &:hover {
      color: #3bb77e;
    }

    &.active {
      color: #3bb77e;
    }
  }
`;
