import styled from "styled-components";

const StripeBannerWrapper = styled.div`
  background-color: ${(props) => props.bgColor};
  position: relative;
  width: 100%;
  height: 48px;
  text-align: center;

  img {
    max-width: 100%;
    height: auto;
  }

  @media screen and (min-width: 575px) {
    .swiper-wrapper {
      transition-timing-function: linear;
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 15px;
  background: transparent;
  color: white;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.3s;
  z-index: 10;

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;

const ImageBanner = ({ banners, handleClose }) => {
  const imageBanner = banners?.main_image_stripe?.[0] || null;

  if (!imageBanner) return null;
  return (
    <>
      <StripeBannerWrapper bgColor={imageBanner.bg_color}>
        <a
          href={imageBanner.link_url}
          target={imageBanner.new_win === "Y" ? "_blank" : ""}
        >
          <img src={imageBanner.P_FileUrl} alt={imageBanner.img_alt} />
        </a>
        <CloseButton onClick={handleClose}>×</CloseButton>
      </StripeBannerWrapper>
    </>
  );
};

export default ImageBanner;
