import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { updateProductCategory } from "../../redux/action/productFiltersAction";
import { connect } from "react-redux";
import Link from "next/link";

const CategorySection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CategorySelect = styled.div`
  margin: 10px 0px 0px 0px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  & button {
    padding: 5px 5px;
    border: 1px solid #253d4e;
    background-color: #fff;
  }
  .general {
    border-color: ${(props) =>
      props.categoryType === "general" ? "#3bb77e" : "#7e7e7e"} !important;
    color: ${(props) =>
      props.categoryType === "general" ? "#ffffff" : "#7e7e7e"} !important;
    background-color: ${(props) =>
      props.categoryType === "general" ? "#3bb77e" : "#ffffff"} !important;
  }
  .type {
    border-color: ${(props) =>
      props.categoryType === "type" ? "#3bb77e" : "#7e7e7e"} !important;
    color: ${(props) =>
      props.categoryType === "type" ? "#ffffff" : "#7e7e7e"} !important;
    background-color: ${(props) =>
      props.categoryType === "type" ? "#3bb77e" : "#ffffff"} !important;
  }
`;

const CategoryListWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const CategoryList = styled.ul`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin-top: 10px;
`;

const Category = styled.li`
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #f2f3f4;
  height: 40px;
  padding: 5px 5px;
  line-height: 1;
  word-break: keep-all;
  :hover {
    border-color: #3bb77e;
  }
  & a {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #696969;
  }
  & img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }
`;

const MobileCategory = ({ categoriRef, dummyList }) => {
  const router = useRouter();
  const [category, setCategory] = useState([]);
  const [categoryType, setCategoryType] = useState("general");

  const fetchCategory = async () => {
    setCategory(
      dummyList.category?.filter((el) =>
        categoryType === "type" ? el.division === "T" : el.division === "S"
      )
    );
  };

  const handleCategoryType = (type) => {
    setCategoryType(type);
    fetchCategory(type);
  };

  useEffect(() => {
    if (dummyList.category) {
      fetchCategory();
    }
  }, [dummyList, categoryType]);

  return (
    <CategorySection>
      <CategorySelect categoryType={categoryType}>
        <button
          className="general"
          value={"general"}
          onClick={(e) => handleCategoryType("general")}
        >
          포장재 먼저 선택
        </button>
        <button
          className="type"
          value={"type"}
          onClick={(e) => handleCategoryType("type")}
        >
          포장할 상품 먼저 선택
        </button>
      </CategorySelect>
      <CategoryListWrapper>
        <CategoryList>
          {category?.map((item, i) => (
            <Category key={i}>
              <a href={`/products/?code=${item.code}`}>
                <img src={item.image} alt="" />
                {item.name}
              </a>
            </Category>
          ))}
        </CategoryList>
      </CategoryListWrapper>
    </CategorySection>
  );
};

const mapStateToProps = (state) => ({
  dummyList: state.dummyList,
});

export default connect(mapStateToProps, null)(MobileCategory);
